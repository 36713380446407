#root{
  height: 100vh;
  width: 100vw;
  font-size: 26px;
}

/*#mainContainer{*/
/*  !*display: flex;*!*/
/*  justify-content: center;*/
/*  align-items:center;*/
/*  height: 100%;*/
/*}*/

/*.row{*/
/*  !*display: flex;*!*/
/*  justify-content: space-between;*/
/*  cursor: pointer;*/
/*  padding: 10px;*/
/*}*/

/*!*.row:hover{*!*/
/*!*  background-color: #ea650D;*!*/
/*!*}*!*/
