body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*.Buttons{*/
/*  padding: 12vh 12vw;*/
/*  border: 5px solid #EA650D;*/
/*  color: #EA650D;*/
/*  background-color: white;*/
/*  font-size: 20px*/
/*}*/
/*.FlowContainer{*/
/*  margin:12vh 12vw;*/
/*}*/
/*textarea{*/
/*  width: 100%;*/
/*  height: 20vh;*/
/*}*/